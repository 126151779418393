const About = () => (
  <div className="nes-container with-title is-centered">
    <p className="title">About</p>
    500 unique Aliens come together to create the Alien Dao! Holding these
    Aliens will allow you to collect free SolMega lotto tickets based on rarity!
    Community wallet is set at 40% of the Royalties!<br/><br/>
    <ul>
      <li>Mythic = 6 tickets</li>
      <li>Legendary = 5</li>
      <li>Epic = 4</li>
      <li>Rare = 3</li>
      <li>Uncommon = 2 </li>
      <li>Common = 1 ticket</li>
    </ul>
  </div>
);

export default About;
