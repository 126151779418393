const Navbar = () => (
  <header>
    <div className="container nav-container">
      <div className="nav-brand">
        <a href="/">
          <img src="/images/logo.png" alt="Solana Alien Club" height="75"/>
        </a>
      </div>
      <div className="social-buttons">
        <div className="share">
          <a
            href="https://twitter.com/SolanaAlienClub"
            target="_blank"
            rel="noreferrer"
          >
            <i className="nes-icon twitter"></i>
          </a>
          <a
            href="https://discord.gg/v8v4SbBJwx"
            target="_blank"
            rel="noreferrer"
          >
            <i className="nes-icon discord"></i>
          </a>
        </div>
      </div>
    </div>
  </header>
);

export default Navbar;
