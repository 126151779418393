const Team = () => (
  <section className="coreteam">
    <h3 className="topic-title">
      <i className="nes-icon star" />
      Team Members
    </h3>
    <div className="coreteam-members">
      <section className="nes-container member-card">
        <div className="avatar">
          <img
            data-src="https://github.com/thebrainium.png?size=80"
            alt="thebrainium"
            src="https://github.com/thebrainium.png?size=80"
          />
        </div>
        <div className="profile">
          <h4 className="name">TheBrainium</h4>
          <p>Creator, Artist</p>
          <div>
            <a href="https://github.com/thebrainium" target="_blank" rel="noopener noreferrer">
              <i className="nes-icon github"></i>
            </a>
            <a href="https://twitter.com/thebrainium" target="_blank" rel="noopener noreferrer">
              <i className="nes-icon twitter"></i>
            </a>
          </div>
        </div>
      </section>
      <section className="nes-container member-card">
        <div className="avatar">
          <img
            data-src="https://github.com/mattvv.png?size=80"
            alt="mattvv"
            src="https://github.com/mattvv.png?size=80"
          />
        </div>
        <div className="profile">
          <h4 className="name">mattvv</h4>
          <p>Developer</p>
          <div>
            <a href="https://github.com/mattvv" target="_blank" rel="noopener noreferrer">
              <i className="nes-icon github"></i>
            </a>
            <a href="https://linkedin.com/in/mattvv" target="_blank" rel="noopener noreferrer">
              <i className="nes-icon linkedin"></i>
            </a>
          </div>
        </div>
      </section>
    </div>
  </section>
);

export default Team;
